// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-coach-js": () => import("./../../../src/pages/coach.js" /* webpackChunkName: "component---src-pages-coach-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-ielts-listening-index-js": () => import("./../../../src/pages/ielts/listening/index.js" /* webpackChunkName: "component---src-pages-ielts-listening-index-js" */),
  "component---src-pages-ielts-reading-index-js": () => import("./../../../src/pages/ielts/reading/index.js" /* webpackChunkName: "component---src-pages-ielts-reading-index-js" */),
  "component---src-pages-ielts-speaking-index-js": () => import("./../../../src/pages/ielts/speaking/index.js" /* webpackChunkName: "component---src-pages-ielts-speaking-index-js" */),
  "component---src-pages-ielts-writing-index-js": () => import("./../../../src/pages/ielts/writing/index.js" /* webpackChunkName: "component---src-pages-ielts-writing-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-resources-template-js": () => import("./../../../src/templates/resourcesTemplate.js" /* webpackChunkName: "component---src-templates-resources-template-js" */)
}

